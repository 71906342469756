import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { FormattedMessage, Link as LinkIntl } from 'gatsby-plugin-intl';

import BackgroundTriangle from '../BackgroundTriangle/BackgroundTriangle';

import './CallToActionSection.css';

const CallToActionSection = (props) => {
  return (
    <section className="section-content accent-soft" style={{ position: 'relative', zIndex: 50 }}>
      <BackgroundTriangle left={true} colorTeal={true} />
      <BackgroundTriangle left={false} colorTeal={true} />

      <Container fluid>
        <h2 className="section-headline" dangerouslySetInnerHTML={{ __html: props.content.call_to_action.headline }} />
        <div className="cta-container">
          {props.content?.call_to_action?.text && (
            <p className="text-center" dangerouslySetInnerHTML={{ __html: props.content.call_to_action.text }} />
          )}

          <LinkIntl to="/contact/index.html">
            <Button
              size="lg"
              variant="light"
              style={{
                color: 'white',
                backgroundColor: '#009688',
                minWidth: '230px',
                borderRadius: '8px',
              }}
            >
              <FormattedMessage id="buttons.contact_us" />
            </Button>
          </LinkIntl>
        </div>
      </Container>
    </section>
  );
};

export default CallToActionSection;
