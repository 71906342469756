import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import Landing from '../components/Landing/Landing';
import CallToActionSection from '../components/CallToActionSection/CallToActionSection';
import UspSection from '../components/UspSection/UspSection';
import TimelineSection from '../components/TimelineSection/TimelineSection';
import BlogSummary from '../components/BlogSummary/BlogSummary';

const AboutPage = ({ data }) => {
  const content = data.content.frontmatter;

  return (
    <Layout>
      <SEO title={content.meta.seo_title} description={content.meta.seo_description} />

      <Landing content={content} borderImg decoration />
      <CallToActionSection content={content} />
      <TimelineSection content={content} />
      <UspSection content={content} />
      <BlogSummary content={content} newestPosts={data.newestPosts} noAccent />
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query($language: String!) {
    newestPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { collection: { eq: "blog" }, fields: { published: { eq: true }, language: { eq: $language } } }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 100)
        fields {
          slug
        }
        frontmatter {
          image {
            childImageSharp {
              fluid(maxWidth: 512, maxHeight: 384) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          abstract
          author
          dateDe: date(formatString: "DD. MMMM YYYY", locale: "de_DE")
          dateEn: date(formatString: "MMMM DD, YYYY", locale: "en_US")
        }
      }
    }
    content: markdownRemark(
      collection: { eq: "pages" }
      folder: { eq: "about" }
      fields: { language: { eq: $language } }
    ) {
      frontmatter {
        meta {
          name
          slug
          seo_title
          seo_description
        }
        hero {
          headline
          text
          image {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_tablet {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        call_to_action {
          headline
          text
        }
        timeline {
          headline
          text
          elements {
            name
            text
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        usp {
          headline
          text
          elements {
            name
            text
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
