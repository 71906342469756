import React from 'react';
import PropTypes from 'prop-types';

import * as classes from './BackgroundTriangle.module.css';
import imgLeft from '../../images/triangle-left.svg';
import imgRight from '../../images/triangle-right.svg';
import imgLeftTeal from '../../images/triangle-left-teal.svg';
import imgRightTeal from '../../images/triangle-right-teal.svg';

const BackgroundTriangle = (props) => {
  const classNames = [classes.Wrapper];
  let image;

  if (props.left) {
    classNames.push(classes.Left);
    image = props.colorTeal ? imgLeftTeal : imgLeft;
  } else {
    classNames.push(classes.Right);
    image = props.colorTeal ? imgRightTeal : imgRight;
  }

  if (props.colorTeal) {
    classNames.push(classes.Teal);
  }

  return (
    <div className={classNames.join(' ')}>
      <img src={image} className={classes.Image} alt="Background Triangle" />
    </div>
  );
};

BackgroundTriangle.propTypes = {
  left: PropTypes.bool,
  colorTeal: PropTypes.bool,
};

export default BackgroundTriangle;
