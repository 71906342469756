import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage, Link as LinkIntl } from 'gatsby-plugin-intl';

import BlogCards from '../BlogCards/BlogCards';

import './BlogSummary.css';

const TestimonialsSummary = (props) => {
  const { newestPosts } = props;
  const posts = newestPosts.nodes;

  return (
    <section id="blog" className={`section-content ${!props.noAccent && 'accent-soft'}`}>
      <Container fluid>
        <h2
          className="section-headline"
          dangerouslySetInnerHTML={{ __html: props.content?.blog?.headline ?? 'Blog' }}
        />
        {props.content?.blog?.text && (
          <p className="section-headline-small" dangerouslySetInnerHTML={{ __html: props.content.blog.text }} />
        )}
        <div className="blog-summary-row">
          <div className="position-absolute">
            <div className="blog-summary-decoration-left" />
          </div>
          <Row className="blog-summary-row-cards">
            <BlogCards posts={posts} noFilter />
          </Row>
          <div className="position-absolute">
            <div className="blog-summary-decoration-right" />
          </div>
          <Row>
            <Col xs={12}>
              <div className="blog-summary-button">
                <LinkIntl to="/blog/index.html">
                  <Button
                    size="lg"
                    variant="light"
                    style={{
                      color: 'white',
                      backgroundColor: '#009688',
                      minWidth: '230px',
                      borderRadius: '8px',
                    }}
                  >
                    <FormattedMessage id="buttons.all_articles" />
                  </Button>
                </LinkIntl>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default TestimonialsSummary;
