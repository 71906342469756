const bullets = [
  {
    background: '#F1F1FD',
    color: '#0A61F7',
  },
  {
    background: '#FFF8E4',
    color: '#ffb300',
  },
  {
    background: '#FEF3EF',
    color: '#C5461E',
  },
];

export default bullets;
