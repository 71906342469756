import React from 'react';
import Img from 'gatsby-image';
import { FormattedMessage, Link as LinkIntl } from 'gatsby-plugin-intl';
import { Button, Col, Container, Row } from 'react-bootstrap';

import bullets from '../../config/colors/bullets';

import './Landing.css';

const Landing = (props) => (
  <section id="landing">
    <Container id="landing-container" style={{ marginTop: props.decoration ? '150px' : '100px' }} fluid>
      <div className="landing-hero-row">
        <Row>
          <Col xs={12} md={6} className="landing-hero-left">
            <h1 dangerouslySetInnerHTML={{ __html: props.content.hero.headline }} />
            {props.content?.hero?.text && <p dangerouslySetInnerHTML={{ __html: props.content.hero.text }} />}
            {props.content?.services?.elements?.length > 0 && (
              <div id="service-container">
                {props.content.services.elements.map((element, index) => (
                  <div style={{ background: element.background || bullets[index % 3].background }} key={element.name}>
                    <span style={{ color: element.color || bullets[index % 3].color }}>{element.name}</span>
                  </div>
                ))}
              </div>
            )}
            {props.content?.hero?.bullets?.length > 0 && (
              <div id="service-container">
                {props.content.hero.bullets.map((element, index) => (
                  <div style={{ background: element.background || bullets[index % 3].background }} key={element.name}>
                    <span style={{ color: element.color || bullets[index % 3].color }}>{element.name}</span>
                  </div>
                ))}
              </div>
            )}
            <LinkIntl to="/contact/index.html">
              <Button
                size="lg"
                variant="light"
                style={{
                  color: 'white',
                  backgroundColor: '#009688',
                  minWidth: '230px',
                  minHeight: '48px',
                  marginTop: '50px',
                  borderRadius: '8px',
                  fontSize: '18px',
                  lineHeight: '22px',
                }}
              >
                <FormattedMessage id="buttons.contact_us" />
              </Button>
            </LinkIntl>
          </Col>
          <Col md={6} className="d-xs-none landing-hero-right">
            <div className="d-sm-none d-md-block d-lg-none">
              {props.decoration && <div className="hero-decoration1" />}
              <Img
                fluid={props.content.hero.image_tablet.childImageSharp.fluid}
                alt={props.content.hero.alt}
                style={props.borderImg ? { borderRadius: '8px' } : {}}
              />
              {props.decoration && <div className="hero-decoration2" />}
            </div>
            <div className="d-sm-none d-md-none d-lg-block">
              {props.decoration && <div className="hero-decoration1" />}
              <Img
                fluid={props.content.hero.image.childImageSharp.fluid}
                alt={props.content.hero.alt}
                style={props.borderImg ? { borderRadius: '8px' } : {}}
              />
              {props.decoration && <div className="hero-decoration2" />}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  </section>
);

export default Landing;
