import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Img from 'gatsby-image';

import './TimelineSection.css';

const TimelineSection = (props) => {
  if (!props.content?.timeline.headline || !props.content?.timeline?.elements?.length) {
    return null;
  }

  return (
    <section className="section-content">
      <Container fluid>
        <h2
          className="section-headline underline"
          dangerouslySetInnerHTML={{ __html: props.content.timeline.headline }}
        />
        {props.content?.timeline?.text && (
          <p className="text-center" dangerouslySetInnerHTML={{ __html: props.content.timeline.text }} />
        )}

        <div className="timeline-wrapper">
          <Row>
            <Col mg={12} lg={6}>
              <div className="timeline-element-container">
                {props.content.timeline.elements.map((element, index) => (
                  <div className="timeline-element" key={`timeline-element-${index}`}>
                    <div className="timeline-element-bullet">
                      <div className="timeline-element-bullet-circle">
                        <span>{index + 1}</span>
                      </div>
                      <div className="timeline-element-bullet-line" />
                    </div>
                    <div className="timeline-element-text">
                      <p dangerouslySetInnerHTML={{ __html: element.text }} />
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col lg={6} className="d-xs-none d-sm-none d-md-none d-lg-block">
              <Img
                fluid={props.content.timeline.elements[0].image.childImageSharp.fluid}
                alt={props.content.timeline.headline}
                className="w-75 float-right"
                style={{ borderRadius: '8px' }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default TimelineSection;
