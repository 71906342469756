import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Img from 'gatsby-image';

import './UspSection.css';

const UspSection = (props) => {
  if (!props.content?.usp?.headline || !props.content?.usp?.elements?.length) {
    return null;
  }

  return (
    <section className="section-content accent-soft">
      <Container fluid>
        <h2 className="section-headline" dangerouslySetInnerHTML={{ __html: props.content.usp.headline }} />
        <div className="usp-container">
          <Row>
            {props.content?.usp?.elements.map((element) => (
              <Col xs={12} md={6} lg={12 / props.content.usp.elements.length} key={element.name}>
                <div className="usp-element-image">
                  {element.image.extension === 'svg' ? (
                    <img src={element.image.publicURL} alt={element.name} className="usp-element-image-svg" />
                  ) : (
                    <Img
                      className="usp-element-image-img"
                      fluid={element.image.childImageSharp.fluid}
                      alt={element.name}
                    />
                  )}
                </div>
                <div className="usp-element-title">
                  <h3 dangerouslySetInnerHTML={{ __html: element.name }} />
                </div>
                <div className="usp-element-text">
                  <p dangerouslySetInnerHTML={{ __html: element.text }} />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default UspSection;
